/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import './SidebarMenuMain.scss'
import {useIntl} from 'react-intl'
import {Logout, AuthPage, useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser, getBot} = useAuth()

  return (
    <>
      {/*<SidebarMenuItem
        to='/inbox'
        icon='/media/icons/duotune/general/comments-solid.svg'
        title='Inbox'
        fontIcon='bi-layers'
      />*/}

      {/*<SidebarMenuItem
        to='/contacts'
        icon='/media/icons/duotune/general/user-group-solid.svg'
        title='Contacts'
        fontIcon='bi-layers'
      />*/}

      {/*<SidebarMenuItem
        to='/content'
        icon='/media/icons/duotune/general/message-solid.svg'
        title='Content'
        fontIcon='bi-layers'
      />*/}

      {/*<SidebarMenuItem
        to='/add-ons'
        icon='/media/icons/duotune/general/square-plus-solid.svg'
        title='Add-ons'
        fontIcon='bi-layers'
      />*/}

      {/*<SidebarMenuItem
        to='/groups'
        icon='/media/icons/duotune/general/users-rectangle-solid.svg'
        title='Groups'
        fontIcon='bi-layers'
      />*/}

      {/*<SidebarMenuItem
        to='/settings'
        icon='/media/icons/duotune/general/gear-solid.svg'
        title='Settings'
        fontIcon='bi-layers'
      />*/}
  {currentUser && !currentUser.is_affiliate && <>

      <SidebarMenuItem
        to='/campaigns'
        icon='/media/icons/duotune/general/comments-solid.svg'
        title={intl.formatMessage({id: 'MENU.CAMPAIGNS'})}
        fontIcon='bi-layers'
      />
      {/*<SidebarMenuItem
        to='/campaigns?groups'
        icon='/media/icons/duotune/general/comments-solid.svg'
        title={intl.formatMessage({id: 'MENU.GROUPS'})}
        fontIcon='bi-layers'
      />*/}
      <SidebarMenuItem
        to='/profiles'
        icon='/media/icons/duotune/general/comments-solid.svg'
        title={intl.formatMessage({id: 'MENU.CONNECT_PROFILE'})}
        fontIcon='bi-layers'
      />      
      {/*<SidebarMenuItem
        to='/plans'
        icon='/media/icons/duotune/general/comments-solid.svg'
        title={intl.formatMessage({id: 'MENU.PLANS'})}
        fontIcon='bi-layers'
      />*/}
      <SidebarMenuItem
        to='/stats'
        icon='/media/icons/duotune/general/comments-solid.svg'
        title={intl.formatMessage({id: 'MENU.STATS'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/update-payment-information'
        icon='/media/icons/duotune/general/comments-solid.svg'
        title={intl.formatMessage({id: 'MENU.UPDATE_PAYMENT'})}
        fontIcon='bi-layers'
      />
</>}
{currentUser && currentUser.is_affiliate && <SidebarMenuItemWithSub
        to='/affiliate'
        title={intl.formatMessage({id: 'MENU.AFFILIATE'})}
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='affiliate/dashboard' title={intl.formatMessage({id: 'MENU.AFFILIATE'})} hasBullet={true} />

        <SidebarMenuItemWithSub
        to='/affiliate'
        title={intl.formatMessage({id: 'MENU.CONTENT'})}
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='affiliate/content/video-recommendations' title={'סרטוני המלצות'} hasBullet={true} />
        <SidebarMenuItem to='affiliate/content/advertising-posts' title={'פוסטים לפרסום'} hasBullet={true} />
        <SidebarMenuItem to='affiliate/content/cold-calling' title={'כלים ודוגמאות לסגירת לידים קרים'} hasBullet={true} />
        <SidebarMenuItem to='affiliate/content/email-content' title={'תוכן לשליחה במייל'} hasBullet={true} />
        <SidebarMenuItem to='affiliate/content/advertisement-videos' title={'סרטונים שיווקיים'} hasBullet={true} />
      </SidebarMenuItemWithSub>


        <SidebarMenuItem to='/affiliate/payment-request' title={intl.formatMessage({id: 'MENU.PAYMENT_REQUEST'})} hasBullet={true} />
        {/*<SidebarMenuItem to='/affiliate/tutorials' title={intl.formatMessage({id: 'MENU.TUTORIALS'})} hasBullet={true} />*/}



        <SidebarMenuItem to='/affiliate/content' title={intl.formatMessage({id: 'MENU.CONTENT'})} hasBullet={true} />
        <SidebarMenuItem to='/affiliate/leads' title={intl.formatMessage({id: 'MENU.LEADS'})} hasBullet={true} />



      </SidebarMenuItemWithSub>}











      {/*<SidebarMenuItem
        to='/invoices'
        icon='/media/icons/duotune/general/comments-solid.svg'
        title={intl.formatMessage({id: 'MENU.INVOICES'})}
        fontIcon='bi-layers'
      />*/}
      {/*<SidebarMenuItem
        to='/scheduling-distribution'
        icon='/media/icons/duotune/maps/map007.svg'
        title={intl.formatMessage({id: 'MENU.SCHEDULING_DISTRIBUTION'})}
        fontIcon='bi-layers'
      />*/}
    </>
  )
}

export {SidebarMenuMain}
