// @ts-nocheck fff

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import './index.scss'
import {GroupWidget} from './GroupWidget'
import {GroupTable} from './GroupTable'
import {useAuth} from '../../../app/modules/auth'
import {Link, useLocation, useMatch, useNavigate, useSearchParams} from 'react-router-dom'
import { ReactMediaLibrary } from "react-media-library";
import {useIntl} from 'react-intl'
import {useDropzone} from 'react-dropzone';
import styled_c from 'styled-components';
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1, CreateCampaignModal} from '../../../_metronic/partials'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import axios from 'axios'
import clsx from 'clsx'
import {getUserByToken, login} from '../../modules/auth/core/_requests'
import {Field, ErrorMessage} from 'formik'


import { PieChart } from '@mui/x-charts/PieChart';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';


const newCampaignSchema = Yup.object().shape({
  name: Yup.string()
    // .email('Wrong email format')
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})



const API_URL = process.env.REACT_APP_API_URL
const CancelToken = axios.CancelToken;


  const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
  }

  const Container = styled_c.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  `;


  function StyledDropzone(props) {
    const intl = useIntl()

    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject
    } = useDropzone({
      accept: {'image/*': []},
      onDropAccepted: async (acceptedFiles) => {

        acceptedFiles.forEach((file) => {
          const reader = new FileReader();

          reader.onabort = () => console.log("file reading was aborted");
          reader.onerror = () => console.log("file reading has failed");
          reader.onload = async () => {

            let fd = new FormData();
            fd.append('file',file);

            var res = await axios.post(`${API_URL}/upload-file`, fd, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });

            props.post.media.push(res.data.location);
            props.update()
          };
          reader.readAsArrayBuffer(file);
        });



      },
      onDropRejected: async (a) => {
        alert('Error uploading file')
      }
    });
    
    return (
      <Container {...getRootProps({isFocused, isDragAccept, isDragReject})}>
        <Field {...getInputProps()} />
        <p>{intl.formatMessage({id: 'GENERAL.DRAG_FILES'})}</p>
      </Container>
    );
  }



const initialValues = {};

const PlansWrapper: FC = (props) => {
  const {auth, currentUser, logout, getBot, setCurrentBot, currentBot, setBot, saveBot, googleLogin, getQRCode, setCurrentUser} = useAuth()
  const intl = useIntl();

  const [searchParams, setSearchParams] = useSearchParams();

  var coupon = searchParams.get("c")?.substring(1);
  coupon = '';
  var eee = searchParams.get("e")?.substring(1);
  var aff_code = localStorage.getItem('aff', '');

  var multi_plans = [
      {
        trans_title: 'חבילה לפרופיל אישי יחיד',
        priceMonth: '490',
        priceAnnual: 490*8,
        posts: '200',
        real_profile: 1,
        coupon: 'G1PmSaC',
      },
      {
        trans_title: 'חבילה ל2 פרופילים אישיים',
        priceMonth: '550',
        priceAnnual: 550*8,
        posts: '200',
        real_profile: 2,
        coupon: 'rUCvhCr',
      },
      {
        trans_title: 'חבילה ל3 פרופילים אישיים',
        priceMonth: '700',
        priceAnnual: 700*8,
        posts: '200',
        real_profile: 3,
        coupon: 'i82h0J5',
      },
      {
        trans_title: 'חבילה ל5 פרופילים אישיים',
        priceMonth: '1000',
        priceAnnual: 1000*8,
        posts: '200',
        real_profile: 5,
        coupon: 'TmBQ4xU',
      },
      {
        trans_title: 'חבילה ל10 פרופילים אישיים',
        priceMonth: '1700',
        priceAnnual: 1700*8,
        posts: '200',
        real_profile: 10,
        coupon: 'ryEKx4Y',
      },
      {
        trans_title: 'חבילה ל30 פרופילים אישיים',
        priceMonth: 30*130,
        priceAnnual: 30*130*8,
        posts: '200',
        real_profile: 30,
        coupon: '8swSJkK',
      },
];


var single_profile_plans = [
  {
    title: 'Single Profile',
    trans_title: intl.formatMessage({id: 'Single Profile'}),
    subTitle: 'Best for startups',
    description: 'Optimal for 10+ team size and new startup',
    priceMonth: 490,
    priceMonthAffilate: 490,
    priceAnnual: '2500',
    posts: '50',
    real_profiles: '1',
    profiles: '0',
    currency: '₪',
    default: false,
    custom: false,
  },
];

var c_package = multi_plans.find((a) => a.coupon === coupon);

if (coupon && c_package) {  
  single_profile_plans[0].priceMonth =  c_package.priceMonth / c_package.real_profile;
}
if (eee) {
  var bbb = parseInt(atob(eee));
  if (parseInt(bbb)) {
    single_profile_plans[0].priceMonth = parseInt(bbb);
  }
}

var plans = [
  {
    title: 'Getting Started',
    trans_title: intl.formatMessage({id: 'Getting Started'}),
    subTitle: 'Best for startups',
    description: 'Optimal for 10+ team size and new startup',
    priceMonth: '1,500',
    priceAnnual: '900',
    posts: '200',
    real_profiles: '2',
    profiles: '22',
    currency: '₪',
    default: true,
    custom: false,
    features: [
      {
        title: 'Up to 10 Active Users',
        supported: true,
      },
      {
        title: 'Up to 30 Project Integrations',
        supported: true,
      },
      {
        title: 'Analytics Module',
        supported: true,
      },
      {
        title: 'Finance Module',
        supported: false,
      },
      {
        title: 'Accounting Module',
        supported: false,
      },
      {
        title: 'Network Platform',
        supported: false,
      },
      {
        title: 'Unlimited Cloud Space',
        supported: false,
      },
    ],
  },

  {
    title: 'Advanced',
    trans_title: intl.formatMessage({id: 'Advanced'}),
    subTitle: 'Best for 100+ team size',
    description: 'Optimal for 100+ team size and grown company',
    priceMonth: '2,000',
    priceAnnual: '1,300',
    posts: '300',
    real_profiles: '4',
    profiles: '35',
    currency: '₪',
    default: false,
    custom: false,
    features: [
      {
        title: 'Up to 10 Active Users',
        supported: true,
      },
      {
        title: 'Up to 30 Project Integrations',
        supported: true,
      },
      {
        title: 'Analytics Module',
        supported: true,
      },
      {
        title: 'Finance Module',
        supported: true,
      },
      {
        title: 'Accounting Module',
        supported: true,
      },
      {
        title: 'Network Platform',
        supported: false,
      },
      {
        title: 'Unlimited Cloud Space',
        supported: false,
      },
    ],
  },
  {
    title: 'Pro',
    trans_title: intl.formatMessage({id: 'Pro'}),
    subTitle: 'Best for 100+ team size',
    description: 'Optimal for 100+ team size and grown company',
    priceMonth: '3,000',
    priceAnnual: '2,100',
    posts: '500',
    real_profiles: '7',
    profiles: '50',
    currency: '₪',
    default: false,
    custom: false,
    features: [
      {
        title: 'Up to 10 Active Users',
        supported: true,
      },
      {
        title: 'Up to 30 Project Integrations',
        supported: true,
      },
      {
        title: 'Analytics Module',
        supported: true,
      },
      {
        title: 'Finance Module',
        supported: true,
      },
      {
        title: 'Accounting Module',
        supported: true,
      },
      {
        title: 'Network Platform',
        supported: false,
      },
      {
        title: 'Unlimited Cloud Space',
        supported: false,
      },
    ],
  },
  {
    title: 'VIP',
    trans_title: intl.formatMessage({id: 'VIP'}),
    subTitle: 'Best for 100+ team size',
    description: 'Optimal for 100+ team size and grown company',
    priceMonth: '5,000',
    priceAnnual: '3,100',
    posts: '1,000',
    real_profiles: '10',
    profiles: '100',

    currency: '₪',
    default: false,
    custom: false,
    features: [
      {
        title: 'Up to 10 Active Users',
        supported: true,
      },
      {
        title: 'Up to 30 Project Integrations',
        supported: true,
      },
      {
        title: 'Analytics Module',
        supported: true,
      },
      {
        title: 'Finance Module',
        supported: true,
      },
      {
        title: 'Accounting Module',
        supported: true,
      },
      {
        title: 'Network Platform',
        supported: false,
      },
      {
        title: 'Unlimited Cloud Space',
        supported: false,
      },
    ],
  },
  {
    title: 'Diamond',
    trans_title: intl.formatMessage({id: 'Diamond'}),
    subTitle: 'Best for 100+ team size',
    description: 'Optimal for 100+ team size and grown company',
    priceMonth: '10,000',
    priceAnnual: '5,000',
    posts: '2,500',
    real_profiles: '20',
    profiles: '250',
    currency: '₪',
    default: false,
    custom: false,
    features: [
      {
        title: 'Up to 10 Active Users',
        supported: true,
      },
      {
        title: 'Up to 30 Project Integrations',
        supported: true,
      },
      {
        title: 'Analytics Module',
        supported: true,
      },
      {
        title: 'Finance Module',
        supported: true,
      },
      {
        title: 'Accounting Module',
        supported: true,
      },
      {
        title: 'Network Platform',
        supported: false,
      },
      {
        title: 'Unlimited Cloud Space',
        supported: false,
      },
    ],
  },

  /*{
    title: 'Custom',
    trans_title: intl.formatMessage({id: 'Custom'}),
    subTitle: 'Requet a custom license',
    default: false,
    custom: true,
  },*/
];

if (window.location.hostname.indexOf('autopost.co.il') > -1) {
  props.type = 'single-profile';
}

if (props.type === 'single-profile') {
  plans = single_profile_plans;
}

  const [showCreateCampaignModal, setShowCreateCampaignModal] = useState<boolean>(false)

  const formik = useFormik({
    initialValues,
    validationSchema: newCampaignSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {

      (async () => {
        try {
          var new_campaign = await axios.post(`${API_URL}/create-campaign`, {
            api_token: auth?.api_token || '',
            new_campaign: values,
          });

          const {data: user} = await getUserByToken(auth.api_token)
          setCurrentUser(user)

          return navigate(`/campaigns/${new_campaign.data._id}`);
        } catch (e) {
          console.log(e);
          alert(1234)
        }
      })()


    },
  })

  const location = useLocation();
  const navigate = useNavigate();

  let [campaigns, setCampaigns] = useState([]);

  let unallocated = campaigns.reduce((count, camp) => {
    count -= camp.publish_percentage || 0;
    count = Math.max(count, 0);
    return count;
  }, 100);

  const [display, setDisplay] = useState(false);
  let fileLibraryList: any;
  let setFileLibraryList: any;
  let selectedCampaign: any;
  let setSelectedCampaign: any;
  let selectedPost: any;
  let setSelectedPost: any;

  [fileLibraryList, setFileLibraryList] = useState([]);
  [selectedCampaign, setSelectedCampaign] = useState({});
  [selectedPost, setSelectedPost] = useState({});

  useEffect(() => {
    // TODO Get file list from database
    setFileLibraryList([
      {
        _id: 1,
        title: "Me and my dog",
        size: 294880,
        fileName: "img_3549.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:12:29.866Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
      },
      {
        _id: 2,
        title: "2019 Summer Vacation",
        size: 864483,
        fileName: "201702.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:12:45.018Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
      },
      {
        _id: 3,
        title: "Our new baby",
        size: 586458,
        fileName: "271092.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:19:33.498Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
      },
      {
        _id: 4,
        title: "My new car",
        size: 894665,
        fileName: "photo-107.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:28:39.723Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
      }
    ]);
  }, []);

  async function uploadCallback(
    fileBase64: any,
    fileMeta: any
  ): Promise<any> {
    // TODO Upload file to backend APIs
  }

  function selectCallback(item: any) {
    // Hide modal
    setDisplay(false);
    console.info(item);
  }

  async function deleteCallback(item: any) {
  }


  useEffect(() => {
    getBot();
  }, [currentUser, currentBot])

  let sourceToken: any;
  let setSourceToken: any;
  let posts: any;
  let setPosts: any;
  const [campaignID, setCampaignID] = useState('');
  const [loadingPosts, setLoadingPosts] = useState(false);
  [posts, setPosts] = useState([]);
  [sourceToken, setSourceToken] = useState();


  // alert(JSON.stringify(id))


  const match = useMatch("campaigns/:id");


  useEffect(() => {
    const source = CancelToken.source();

    try {
      sourceToken.cancel('Operation canceled by the user.');
    } catch (e) {

    }

    setSourceToken(sourceToken);

    (async () => {
      try {
        setLoadingPosts(true);
        setPosts([]);
        var posts = await axios.post(`${API_URL}/posts`, {
          api_token: auth?.api_token || '',
          campaign_id: campaignID,
        }, {
          cancelToken: source.token
        });

        posts.data.map((post) => {
          post.media = (post.media_url || '').split(',').filter((a) => a);
        })
        

        setPosts(posts.data);
      } catch (e) {

      }
      setLoadingPosts(false);
    })()

  }, [campaignID]);



  let deleteCampaign = async (campaign) => {
    try {
      var new_campaign = await axios.post(`${API_URL}/delete-campaign`, {
        api_token: auth?.api_token || '',
        campaign: campaign,
      });

      const {data: user} = await getUserByToken(auth.api_token)
      setCurrentUser(user)

      return navigate(`/campaigns`);
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }
  let requestCampaignApproval = async (campaign) => {
    try {

      campaign.loading = true;
      setSelectedCampaign(JSON.parse(JSON.stringify(campaign)));

      var new_campaign = await axios.post(`${API_URL}/request-approve-campaign`, {
        api_token: auth?.api_token || '',
        campaign: campaign,
      });

      const {data: user} = await getUserByToken(auth.api_token)
      setCurrentUser(user)

      campaign.request_for_approval = new Date();

      setSelectedCampaign(JSON.parse(JSON.stringify(campaign)));
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }
  let deletePost = async (post, campaign) => {
    try {
      var new_campaign = await axios.post(`${API_URL}/delete-post`, {
        api_token: auth?.api_token || '',
        post: post,
        campaign: campaign,
      });
      posts.splice(posts.indexOf(post), 1)
      setPosts([...posts]);
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }
  let deletePosts = async (campaign) => {
    try {
      var posts_to_delete = posts.filter((a) => a.selected);
      
      var new_campaign = await axios.post(`${API_URL}/delete-posts`, {
        api_token: auth?.api_token || '',
        ids: posts_to_delete.map((a) => a._id),
        campaign: campaign,
      });

      for (var i = 0; i < posts_to_delete.length; i++) {
        posts.splice(posts.indexOf(posts_to_delete[i]), 1)
      }
      setPosts([...posts]);
      
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }
  let deplicatePosts = async (post, campaign) => {
    try {
      var new_campaign = await axios.post(`${API_URL}/deplicate-posts`, {
        api_token: auth?.api_token || '',
        ids: posts.filter((a) => a.selected).map((a) => a._id),
        campaigns: campaigns.filter((a) => a.selected).map((a) => a._id),
      });
      // posts.splice(posts.indexOf(post), 1)
      setPosts([...posts]);
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }

  let savePost = async (post, campaign) => {
    post.loading = true;
    setPosts([...posts]);

    try {
      var _post = await axios.post(`${API_URL}/save-post`, {
        api_token: auth?.api_token || '',
        post: post,
        campaign: campaign,
      });

      post.updated = false;
      if (!post._id) {
        post._id = _post.data._id;
      }

    } catch (e) {
      console.log(e);
      alert(1234)
    }

    post.loading = false;
    setPosts([...posts]);
  }

  const [currentState, setCurrentState] = useState<'month' | 'annual'>('month')
  const [selected, setSelected] = useState(props.type === 'single-profile' ? 'Single Profile' : 'Getting Started')

  useEffect(() => {
    props.setFieldValue('plan', selected)
    props.setFieldValue('period', currentState)
  }, [selected, currentState]);


  return (
    <>
    <ReactMediaLibrary
      isOpen={display}
      multiple={true}
      multiSelect={true}
      onClose={() => setDisplay(false)}
      fileUploadCallback={() => {}}
      filesSelectCallback={() => {}}
      fileLibraryList={fileLibraryList}
    />




















    <div className='card mb-5 mb-xl-10'>
      <div className=''>

        <div className=''>
          <div className=''>
            {/*<div className='mb-13 text-center'>
              <h1 className='mb-3'>Upgrade a Plan</h1>

              <div className='text-muted fw-bold fs-5'>
                If you need more info, please check{' '}
                <a href='#' className='link-primary fw-bolder'>
                  Pricing Guidelines
                </a>
                .
              </div>
            </div>*/}

            <div className='d-flex flex-column'>
              <div className='nav-group nav-group-outline mx-auto' data-kt-buttons='true'>
                <a
                  
                  className={
                    'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                    (currentState === 'month' && 'active')
                  }
                  onClick={() => {
                    setCurrentState('month')
                  }}
                  data-kt-plan='month'
                >
                  {intl.formatMessage({id: 'GENERAL.MONTHLY'})}
                </a>
                {<a
                  
                  className={
                    'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                    (currentState === 'annual' && 'active')
                  }
                  onClick={() => {
                    setCurrentState('annual')
                  }}
                  data-kt-plan='annual'
                >
                  {intl.formatMessage({id: 'GENERAL.ANNUAL'})}
                </a>}
              </div>

              <div className='row mt-10'>
                <div className='col-lg-6 mb-10 mb-lg-0'>
                  <div className='nav flex-column'>
                    {plans.map((plan, index) => {
                      return (
                        <div
                          onClick={() => {
                            setSelected(plan.title)
                          }}
                          className={
                            `nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6 ` +
                            (index !== plans.length - 1 && 'mb-6 ') +
                            (plan.default && 'active ') +
                            (!plan.custom && 'btn-active btn-active-primary ')
                          }
                          data-bs-toggle='tab'
                          data-bs-target={`#kt_upgrade_plan_${index}`}
                          key={index}
                        >
                          <div className='d-flex align-items-center me-2'>
                            <div className='form-check form-check-custom form-check-solid form-check-success me-6'>
                              <Field
                                className='form-check-input'
                                type='radio'
                                name='plan'
                                value={plan.title}
                                checked={selected === plan.title}
                                onChange={(e) => setSelected(e.target.value)}
                              />
                            </div>

                            <div className='flex-grow-1'>
                              <h2 className='d-flex align-items-center fs-4 fw-bolder flex-wrap'>
                                {plan.trans_title}

                                {plan.label && (
                                  <span className='badge badge-light-success ms-2 fs-7'>
                                    {plan.label}
                                  </span>
                                )}
                              </h2>
                              {props.type !== 'single-profile' && <div className='fw-bold opacity-50'>{intl.formatMessage({id: 'GENERAL.POSTS_SUB_TITLE'}, {posts: plan.posts, profiles: plan.profiles})}</div>}

                            </div>
                          </div>

                          <div className='ms-5'>
                            {plan.custom && (
                              <button className='btn btn-sm btn-primary'>Contact Us</button>
                            )}
                            {!plan.custom && (
                              <>
                                
                                <span className='mb-2'>{plan.currency}</span>

                                {props.type === 'single-profile' && aff_code && <>
                                  <span className='fs-2x fw-bolder'>
                                    <strike>{currentState === 'month' ? plan.priceMonth : plan.priceAnnual}</strike>
                                  </span>

                                  <span className='fs-7 opacity-50'>
                                    /<span data-kt-element='period'>{intl.formatMessage({id: 'AUTH.REGISTER.MON'})}</span> <br/> {intl.formatMessage({id: 'AUTH.REGISTER.NOT_INCLUDING_VAT'})}
                                  </span>

                                  <br/>
                                  <span className='mb-2'>{plan.currency}</span>

                                  <span className='fs-2x fw-bolder'>
                                    {currentState === 'month' ? plan.priceMonthAffilate : plan.priceAnnual}
                                  </span>

                                  <span className='fs-7 opacity-50'>
                                    /<span data-kt-element='period'>{intl.formatMessage({id: 'AUTH.REGISTER.MON'})}</span> <br/> {intl.formatMessage({id: 'AUTH.REGISTER.NOT_INCLUDING_VAT'})}
                                  </span>

                                </>}
                                {props.type === 'single-profile' && !aff_code && <>
                                  <span className='fs-2x fw-bolder'>
                                    {currentState === 'month' ? plan.priceMonth : plan.priceAnnual}
                                  </span>

                                  <span className='fs-7 opacity-50'>
                                    /<span data-kt-element='period'>{intl.formatMessage({id: 'AUTH.REGISTER.MON'})}</span> <br/> {intl.formatMessage({id: 'AUTH.REGISTER.NOT_INCLUDING_VAT'})}
                                  </span>
                                </>}
                                {props.type !== 'single-profile' && <>
                                  <span className='fs-2x fw-bolder'>
                                    {currentState === 'month' ? plan.priceMonth : plan.priceAnnual}
                                  </span>

                                  <span className='fs-7 opacity-50'>
                                    /<span data-kt-element='period'>{intl.formatMessage({id: 'AUTH.REGISTER.MON'})}</span> <br/> {intl.formatMessage({id: 'AUTH.REGISTER.NOT_INCLUDING_VAT'})}
                                  </span>
                                </>}
                              </>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='tab-content rounded h-100 bg-light p-10'>
                    {plans.map((plan, index) => {
                      return (
                        <div key={`custom${index}`}>
                          {!plan.custom && selected == plan.title && (
                            <>
                              <div
                                className={`tab-pane fade` + (plan.default && 'show active')}
                                id={`kt_upgrade_plan_${index}`}
                                key={index}
                              >
                                <div className='pb-5'>
                                  <h2 className='fw-bolder text-dark'>{intl.formatMessage({id: 'GENERAL.PLAN_TITLE_WHATS_IN'}, {name: plan.trans_title})}</h2>

                                  {/*<div className='text-gray-400 fw-bold'>{plan.description}</div>*/}
                                </div>

                                {props.type !== 'single-profile' && <div className='pt-1'>

                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               {plan.posts} פוסטים ביום
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               {plan.posts*30} פוסטים בחודש
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               תזמון אוטומטי מלא של כל הפוסטים
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               תגובות על הפוסטים מפרופילים שונים
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               תיוג והחזרת תגובה על הפוסטים ללקוחות שהתעניינו בפרטים נוספים
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               {plan.profiles} פרופילים לשימוש במתנה כולל ביטוח מקיף
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               ניתן לצרף עד {plan.real_profiles} פרופילים/משתמשי פייסבוק אישיים ללא עלות
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               קמפיינר צמוד שעושה עבורכם את כל העבודה.
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               תגובות מתואמות בפוסטים
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               בחירת קבוצות רלוונטיות
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               העלאת הפוסטים לקבוצות
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               תמיכה טכנית ושירות לקוחות בעברית
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               מדריכים אינטראקטיביים בעברית לשימוש בתוכנה
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>

                                  {/*plan.features!.map((feature, i) => {
                                    return (
                                      <div
                                        className={
                                          `d-flex align-items-center` +
                                          (i !== plan.features!.length - 1 && ' mb-7')
                                        }
                                        key={`${i}-${feature.title}`}
                                      >
                                        {feature.supported && (
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                              {feature.title}
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                        )}
                                        {!feature.supported && (
                                          <>
                                            <span className='fw-bold fs-5 text-gray-400 flex-grow-1'>
                                              {feature.title}
                                            </span>
                                            <KTSVG
                                              path='/media/icons/duotune/general/gen040.svg'
                                              className='svg-icon-1'
                                            />
                                          </>
                                        )}
                                      </div>
                                    )
                                  })*/}
                                </div>}
                                {props.type === 'single-profile' && <div className='pt-1'>

                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                            הפרופיל יפרסם עד גבול היכולת מבלי להסתכן בחסימה.
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>

                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               תזמון אוטומטי מלא של כל הפוסטים
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               תיוג והחזרת תגובה על הפוסטים ללקוחות שהתעניינו בפרטים נוספים
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               קמפיינר צמוד שעושה עבורכם את כל העבודה.
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               בחירת קבוצות רלוונטיות
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               העלאת הפוסטים לקבוצות
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               תמיכה טכנית ושירות לקוחות בעברית
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>
                                      <div
                                        className={
                                          `d-flex align-items-center mb-7`
                                        }
                                      >
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                               מדריכים אינטראקטיביים בעברית לשימוש בתוכנה
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                      </div>

                                  {/*plan.features!.map((feature, i) => {
                                    return (
                                      <div
                                        className={
                                          `d-flex align-items-center` +
                                          (i !== plan.features!.length - 1 && ' mb-7')
                                        }
                                        key={`${i}-${feature.title}`}
                                      >
                                        {feature.supported && (
                                          <>
                                            <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                              {feature.title}
                                            </span>

                                            <KTSVG
                                              path='/media/icons/duotune/general/gen043.svg'
                                              className='svg-icon-1 svg-icon-success'
                                            />
                                          </>
                                        )}
                                        {!feature.supported && (
                                          <>
                                            <span className='fw-bold fs-5 text-gray-400 flex-grow-1'>
                                              {feature.title}
                                            </span>
                                            <KTSVG
                                              path='/media/icons/duotune/general/gen040.svg'
                                              className='svg-icon-1'
                                            />
                                          </>
                                        )}
                                      </div>
                                    )
                                  })*/}
                                </div>}
                              </div>
                            </>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>

            {/*<div className='d-flex flex-center flex-row-fluid pt-12'>
              <button type='reset' className='btn btn-light me-3' data-bs-dismiss='modal'>
                Cancel
              </button>

              <button type='submit' className='btn btn-primary'>
                Upgrade Plan
              </button>
            </div>*/}
          </div>
        </div>






    </div>
    </div>
    </>
  )
}

export {PlansWrapper}
